import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/account-settings": [7],
		"/boards": [8],
		"/boards/[id]": [9],
		"/canvas": [10],
		"/design-system": [11],
		"/(seo)/dnd-character-creator": [3],
		"/journeys": [12],
		"/journeys/[id]": [~13],
		"/library-3d": [15],
		"/library": [14],
		"/login": [16],
		"/(utilities)/pbr-material-visualizer": [5],
		"/plan": [17],
		"/privacy-policy": [18],
		"/reset-password": [19],
		"/(utilities)/seamless-texture-checker": [6],
		"/(seo)/seamless-texture-generator": [4],
		"/sign-up": [20],
		"/terms-of-service": [21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';